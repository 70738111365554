import Team from "./Team";
import Header from "../../components/header/Header";
import {mobileWidth, tabletWidth} from "../../utilis/screenWidthUtils.ts";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store.ts";
import {
    getStatuses,
   UserStatus
} from "../../store/features/Team/teamSlice";
import {useEffect} from "react";
import {fetchUsers} from "../../store/features/Users/usersSlice";


const TeamList = () => {
    const dispatch = useAppDispatch();

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const users = useSelector((state: RootState) => state.users.users);
    const currentUser = useSelector((state:RootState) => state.users.currentUser);
    const statuses = useSelector((state: RootState) => state.team.statuses);

    const usersIds = users?.map(user => user.userId);

    type TeamStatusType = UserStatus & { firstName: string; lastName: string };

    const teamStatuses = statuses?.reduce<TeamStatusType[]>((acc, status: UserStatus) => {
        const user = users.find(user => user.userId == status.userId);
        if (user && status.userId !== currentUser.userId) {
            acc.push({
                ...status,
                firstName: user.firstName || '',
                lastName: user.lastName || '',
            });
        }
        return acc;
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchUsers());
            await dispatch(getStatuses(usersIds));
        };

        fetchData();

        const intervalId = setInterval(async () => {
            await dispatch(getStatuses(usersIds));
        }, 5000);

        return () => clearInterval(intervalId);
    }, [dispatch]);


    return( 
    <div className={`w-full mr-4 ` }>
        <Header header='Team' icon={'1F465'}/>

            <div
                className={`max-h-[calc(100vh-130px)] overflow-x-hidden  overflow-y-scroll flex flex-wrap ${mobileWidth || tabletWidth ? ' px-4' : 'px-6'} ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>

                {Boolean(teamStatuses?.length) && teamStatuses.map((user: any) => (
                    <Team user={user} />
                ))}


            </div>
        </div>

    );
}
export default TeamList;
