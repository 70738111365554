import { call } from "../components/Dialer/services/sipService";
import {RootState, useAppDispatch} from "../store/store";
import {
    getSelectedSpeaker,
    selectSelectedPhoneNumber,
    setCallFrom,
    setCallState
} from "../store/features/calls/dialerSlice";
import {CallStateEnum} from "../components/Dialer/Dialer";
import {useCallOptions} from "../components/Dialer/hooks/useCallOptions";
import {useSelector} from "react-redux";
import ringbackSound from '../assets/sounds/ringback.mp3';
import { useRef, useEffect } from "react";

interface CallOptions {
    msisdn: string;
    fromName?: string;
    isInternal?: boolean;

}
export const useCallService = () => {
    const dispatch = useAppDispatch();
    const audioDevice = useSelector(getSelectedSpeaker);
    const callState = useSelector((state: RootState) => state.dialer.callState);
    const options = useCallOptions();
    const selectedPhoneNumber = useSelector(selectSelectedPhoneNumber);
    const ringbackSoundRef = useRef(new Audio(ringbackSound));

    useEffect(() => {

        if (callState !== CallStateEnum.CONNECTING) {
            ringbackSoundRef.current.pause();
            ringbackSoundRef.current.currentTime = 0;
        }
    }, [callState]);

    const makeCall = ({ msisdn, fromName = '', isInternal = false }: CallOptions) => {
        if (!msisdn) return;

        dispatch(setCallState(CallStateEnum.CONNECTING));
        dispatch(setCallFrom(fromName || msisdn));

        if (isInternal) {
            ringbackSoundRef.current.play();
        }
        call(msisdn, {
            ...options,
            fromUserName: isInternal ? localStorage.getItem('userId') : selectedPhoneNumber.msisdn,
        }, audioDevice);
    };

    return {
        makeCall,
    };
};

