import {Button} from "ch3-ui-lib";
import { clsxMerge } from "../../../utilis/classNameUtils.ts";
import { bgStyles, borderStyles, textStyles } from "./helpers.tsx";
import {useSelector} from "react-redux";

interface DialpadButtonProps {
    label: string;
    subLabel?: string;
    className?: string;
}


const dialpadButtons = [
    {label: "1"},
    {label: "2", subLabel: "ABC"},
    {label: "3", subLabel: "DEF"},
    {label: "4", subLabel: "GHI"},
    {label: "5", subLabel: "JKL"},
    {label: "6", subLabel: "MNO"},
    {label: "7", subLabel: "PQRS"},
    {label: "8", subLabel: "TUV"},
    {label: "9", subLabel: "WXYZ"},
    {label: "+", className: "items-center text-xl font-bold leading-5 text-black rounded-sm bg-zinc-100"},
    {label: "0", subLabel: "+"},
    {label: "#", className: "items-center text-xl font-bold leading-5 text-black rounded-sm bg-zinc-100"},
];
interface KeypadProps {
    keypadClicked: (key: string) => void;
}
function Keypad({ keypadClicked}:KeypadProps) {
    const theme = useSelector((state: any) => state.theme.theme);


  return (
      <>


          <div className={`flex flex-col justify-center pb-6 pt-0  w-full  whitespace-nowrap  max-w-[212px] mx-auto ${theme === 'dark' ? ' bg-[#1C1E21]' : ' bg-white'}`}>
              <div className="flex gap-5 justify-between">
                  {dialpadButtons.slice(0, 3).map((button: DialpadButtonProps) => (
                      <Button className={`w-12 h-12 px-6 py-6 font-bold border-1 border ${theme === 'dark' ? ' bg-[#1C1E21] border-white text-white' : ' bg-white border-black text-black'} `} size={'large'} buttonType={'secondary'}
                              label={button.label} subLabel={button.subLabel}
                              onClick={() =>keypadClicked(button.label)}></Button>
                  ))}
              </div>
              <div className="flex gap-5 justify-between mt-6">
                  {dialpadButtons.slice(3, 6).map((button: DialpadButtonProps) => (
                      <Button className={`w-12 h-12 px-6 py-6 font-bold border-1 border ${theme === 'dark' ? ' bg-[#1C1E21] border-white text-white' : ' bg-white border-black text-black'} `} size={'large'} buttonType={'secondary'}
                              label={button.label} subLabel={button.subLabel}
                              onClick={() =>  keypadClicked(button.label)}/>
                  ))}
              </div>
              <div className="flex gap-5 justify-between mt-6">
                  {dialpadButtons.slice(6, 9).map((button: DialpadButtonProps) => (
                      <Button className={`w-12 h-12 px-6 py-6 font-bold border-1 border ` + clsxMerge(borderStyles({theme}), bgStyles({theme}), textStyles({theme}))} size={'large'} buttonType={'secondary'}
                              label={button.label} subLabel={button.subLabel}
                              onClick={() =>  keypadClicked(button.label)}/>
                  ))}
              </div>
              <div className="flex gap-5 justify-between mt-6">
                  {dialpadButtons.slice(9).map((button: DialpadButtonProps) => (
                      <Button className={`w-12 h-12 px-6 py-6 font-bold border-1 border ` + clsxMerge(borderStyles({theme}), bgStyles({theme}), textStyles({theme}))} size={'large'} buttonType={'secondary'}
                              label={button.label} subLabel={button.subLabel}
                              onClick={() =>  keypadClicked(button.label)}/>
                  ))}
              </div>
          </div>
      </>
  );
}
export default Keypad;
