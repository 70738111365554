import {useIntl} from "react-intl";
import logoItem from './../assets/logo_dark.svg';
import phoneSettings from './../assets/phone-number-settings.svg';
import {Colors} from "./types/Types";
import groupsSettings from './../assets/groups-settings.svg';


const sidebarItems = [
    {
        icon: 'call',
        labelId: 'calls.menu', // Use message ID instead of direct label
        link: '/calls',
    },
    {
        icon: 'account_circle',
        labelId: 'contacts.menu',
        link: '/contacts',
    },
    {
        icon: 'group',
        labelId: 'team.menu',
        link: '/team',
    },
    // {
    //     icon: 'group',
    //     labelId: 'groups.menu',
    //     link: '/groups',
    // },
];

const sidebarSettingsItems = [
    {
        customIcon: groupsSettings,
        labelId: 'groups.menu',
        link: '/groups',
    },
    {
        customIcon: phoneSettings,
        labelId: 'phoneNumbers.menu',
        link: '/phoneNumbers',
    },
];


export const sections = [
    {
        items: sidebarItems,
    },

    {
        title: 'Settings',
        items: sidebarSettingsItems,
    },
];


export const user = {
    name: '',
    iconColor: Colors.green,
    email: '',
    dnd: false,
};


export const translateSections = () => {
    const intl = useIntl();

    // Map through sections and translate labels of items
    const translatedSections = sections.map(section => ({
        ...section,
        items: section.items.map(item => ({
            ...item,
            label: intl.formatMessage({ id: item.labelId }),
        })),
    }));

    return translatedSections;
};
export const logo = <img src={logoItem} />;
