import Header from "../../components/header/Header";
import {ChColumn, ChInput} from "ch3-ui-lib";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {getUserMsisdns, selectTotalMsisdnsCount, selectUserMsisdns} from "../../store/features/Msisdns/msisdnsSlice";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {FlagImage} from "react-international-phone";
import {Link} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";
import {useAppDispatch, RootState} from "../../store/store";
import noGroupsIcon from "../../assets/empty.svg";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { borderStyles, boxStyles } from "./helpers.tsx";

export const PhoneNumbers = () => {
    const theme = useSelector((state:any) => state.theme.theme);
    const [phrase, setPhrase] = useState<string>('');
    const dispatch = useAppDispatch();
    const msisdns = useSelector(selectUserMsisdns);
    const totalCount = useSelector(selectTotalMsisdnsCount)

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const isMobile = useMediaQuery('(max-width: 960px)');



    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getUserMsisdns(phrase));
        }
        fetchData();

    }, [phrase, dispatch]);


    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 1000), // 1 second delay
        []
    );
   const handleDataChange = (data: any) => {
        console.log(data);
    }

    return(
        <div className='w-full'>
            <Header header='Phone numbers' icon={'1F4F1'}/>
            <div className={`${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <div className='mx-8 flex justify-end mb-6'>
           <ChInput placeholder={'Search'} className={`${isMobile ? ' ' : ' min-w-[375px]'}`} prefixIcon={'search'} callback={(value: string) => handlePhraseChange(value)} />
                </div>

                <div className={`h-[calc(100vh-300px)] mr-4 overflow-y-scroll ${isMobile ? ' ' : ' pr-8 pl-8'}`}>
                    <div className={clsxMerge(boxStyles({theme}))}>
                    <p className={`text-lg font-semibold px-4 py-5 border-x border-t ` + clsxMerge(borderStyles({theme}))}><FormattedMessage

                        id='phoneNumbers.list'/>
                        <span className={`border  rounded-sm px-2 text-xs mx-2 py-1 ` + clsxMerge(borderStyles({theme}))}>
        {totalCount}
        </span>

                    </p>
                    <table className={`w-full border ` + clsxMerge(borderStyles({theme}))}>
                        <thead>
                        <tr className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                            <th className='pl-4 py-3 w-full' onClick={() => handleDataChange([])}
                            ><ChColumn propertyName='name' label={'Name'} /></th>
                        </tr>
                        </thead>
                        <tbody>
                        {msisdns.map((msisdn) => (
                            <tr key={msisdn.msisdnId}>

                                    <td className='pl-4 py-3 w-full cursor-pointer'>
                                        <Link to={`/phoneNumbers/${msisdn.msisdnId}`}>
                                        <div className='flex gap-3'>
                                            <div className='text-2xl items-end flex'>
                                                { hexToEmoji(msisdn.icon, 'w-8 h-8') }
                                            </div>
                                            <div>
                                                <div className='text-sm'>
                                                    {msisdn.label ?? 'No name'}
                                                </div>
                                              <div className='flex items-center text-xs'>
                                                  <FlagImage iso2={getFlag(msisdn.msisdn)} size={16} />
                                                 <div className={'ml-2'}>
                                                     {getFormatedPhoneNumber(msisdn.msisdn, true)}
                                                 </div>
                                              </div>
                                            </div>

                                        </div>
                                        </Link>
                                    </td>
                            </tr>
                        ))}
                        {msisdns.length === 0 && phrase &&
                            <div className='flex text-center  justify-center'>
                                <div className='w-[450px]  m-14'>
                                    <div className='flex  justify-center w-full'>
                                        <img className='m-4' src={noGroupsIcon}/>
                                    </div>

                                    <h1 className='font-bold'>
                                        No phone numbers found
                                    </h1>
                                    <div className='mb-6 mt-2'>
                                        No phone numbers found matching your search. Please double-check your criteria.
                                    </div>

                                </div>

                            </div>

                        }
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>

    )
}
