import {Icon} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { bgStyles, textStyles } from "./helpers.tsx";
import {useSelector} from "react-redux";

const sections = [{
    name: 'Audio',
    elements: [{
        label: 'Audio Settings',
        link: '/settings/audio'
    }]
}];
const Settings = () => {
    const theme = useSelector((state: any) => state.theme.theme);

    return (

        <div className={`flex relative h-full flex-col max-w-xs  z-20 ` + clsxMerge(bgStyles({theme}))}>
            <header className={`flex justify-between p-4 text-2xl font-semibold leading-8  whitespace-nowrap ` + clsxMerge(textStyles({theme}))}>
                <h5>Settings</h5>
                <Link to={'/'}> <Icon iconName={'close'} className='cursor-pointer'/></Link>
            </header>
            {sections.map((section) => {

                return (
                    <>
                        <h2 className={`px-5 mt-4 text-xs font-semibold tracking-wider leading-5 uppercase ` + clsxMerge(textStyles({theme}))}>
                            {section.name}
                        </h2>
                        {section.elements.map((element) => (
                            <Link to={element.link}>
                                <div
                                    className={`flex items-center justify-between gap-3 self-center px-5 mt-2 w-full text-base font-semibold leading-6 max-w-[328px] ` + clsxMerge(textStyles({theme}))}>
                                    <span>{element.label}</span>
                                    <Icon iconName={'chevron_right'}/>
                                </div>
                            </Link>
                        ))}
                    </>


                )
            })}
        </div>
    );
};

export default Settings;
