import {ChInput, Icon} from "ch3-ui-lib";
import { useState} from "react";
import { bgStyles, textStyles } from "./helpers.tsx";
import {useSelector} from "react-redux";
import { clsxMerge } from "../../../utilis/classNameUtils.ts";

interface SelectListItemProps {
    label: any;
    selectedItemId?: number;
    items: any[];
    isOpened: boolean;
    onSelect: Function;
    toggleIsOpened: () => void;
    selectedItem: any;
}

const SelectList = ({label, selectedItem, items, isOpened,toggleIsOpened, onSelect}:SelectListItemProps) => {
    const [filter, setFilter] = useState('');
    const theme = useSelector((state: any) => state.theme.theme);

    const handleSelect = (index:number) => {

        onSelect(index);
    }
    const filteredItems = items?.filter((item) => {

        const keys = Object.keys(item);

        return keys.some((key) =>
            item[key]?.toString().toLowerCase().includes(filter?.toLowerCase())
        );
    });
    return (
       <>{ isOpened &&
         <>  <div onClick={ () => toggleIsOpened()} className='top-0 left-0 w-full h-full fixed'></div>
           <div className={`flex absolute z-30 flex-col max-w-xs w-[318px] h-[670px] `  + clsxMerge(bgStyles({theme}))}>
            <header  className={`flex items-center justify-between  p-4 text-base font-semibold leading-8 whitespace-nowrap ` + clsxMerge(bgStyles({theme}), textStyles({theme}))}>
                <div>{label}</div>
                <button onClick={ () => toggleIsOpened()}>
                    <Icon  iconName={'close'} className='cursor-pointer' />
                </button>
            </header>
           <div className={`p-4 ` +clsxMerge(bgStyles({theme}))}>
               <ChInput className={'min-w-[286px]'} prefixIcon={'search'} callback={(value:string) => setFilter(value)} placeholder='Search phone number' />
           </div>
           {filteredItems.map((item, index: number) => (
               <div onClick={() => {
                   handleSelect(index);
               }
               } key={index} className={`p-4 flex items-center justify-between text-sm cursor-pointer ` + clsxMerge(bgStyles({theme}))}>
                   <div>{item.label}</div>
                   {selectedItem == item.id && <Icon className={'p-0 w-4 h-4 cursor-pointer'} iconName={'check_circle'} /> }
               </div>
           ))}

        </div>
         </> } </>
    )
}
export default SelectList;
