import Header from "../../components/header/Header";
import {Avatar, Button, ChInput, ChModal, ChRadio, Icon} from "ch3-ui-lib";
import AddContactModal from "./AddContactModal";
import {useEffect, useRef, useState} from "react";
import 'react-international-phone/style.css';
import { Link, useSearchParams } from "react-router-dom";
import { Colors } from "../../utilis/types/Types";
import { RootState, useAppDispatch } from "../../store/store";
import { addContact, fetchContactById, updateContact } from "../../store/features/Contacts/contactsSlice";
import { useSelector } from "react-redux";
import { fetchGroups, Group } from "../../store/features/groups/groupsSlice";
import { fetchUsers, User } from "../../store/features/Users/usersSlice";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DeleteContactModal from "./DeleteContact";
import { ClosedDialerMargin, OpenDialerMargin } from "../../utilis/dialerWidthUtils.ts";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {isValidPhoneNumber} from "libphonenumber-js/max";
import {removeEmptyFields} from "../../utilis/removeEmptyFields";
import {validateEmail} from "../../utilis/commonValidators";

import { borderStyles, boxStyles } from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";

import {PhoneNumberInput} from "../../components/Dialer/components/PhoneInput";


const RadioGroup = [{
    id: 'r1',
    label: 'Public',
    description: 'Is a contact that can be viewed and edited by everyone in your organization.',
    value: 'PUBLIC',
    callback: () => {
        console.log('Radio clicked!');
    }
}, {
    id: 'r2',
    label: 'Private',
    description: 'Is a contact that is visible only to you.',
    value: 'PRIVATE',
    callback: () => {
        console.log('Radio clicked!');
    }
},
    {
        id: 'r3',
        label: 'Shared',
        description: 'Is a contact that can be viewed and edited by assigned people.',
        value: 'SHARED',
        callback: () => {
            console.log('Radio clicked!');
        }
    }];

const AddEditContact = () => {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const clientNumber = searchParams.get('phoneNumber');
    const isNewContact = !Boolean(id);
    const theme = useSelector((state: any) => state.theme.theme);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const contactData = useSelector((state: any) => state.contacts.contactById);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const isMobile = useMediaQuery('(max-width: 960px)');


    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            phoneNumbers: [
                {
                    msisdn: clientNumber ?? '',
                    main: true,
                },
            ],
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            availability: 'PUBLIC',
            sharedForUsers: [] as User[],
            sharedForGroups: [] as Group[],
            createdAt: new Date().toISOString(),
            createdBy: localStorage.getItem('userId') ?? '1',
        },
    });

    const { fields, append } = useFieldArray({
        control,
        name: 'phoneNumbers',
    });


    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!isNewContact) {
                await dispatch(fetchContactById(Number(id))); // Fetch the contact by id
            }
        };
        fetchData();
    }, [dispatch, id, isNewContact]);
    useEffect(() => {
        if (!isNewContact && contactData) {
            setValue('firstName', contactData.firstName || '');
            setValue('lastName', contactData.lastName || '');
            setValue('email', contactData.email || '');
            setValue('company', contactData.company || '');
            setValue('phoneNumbers', contactData.phoneNumbers || [{ msisdn: null , main: true }]);
            setValue('availability', contactData.availability || 'PUBLIC');
            setValue('sharedForUsers', contactData.sharedForUsers || []);
            setValue('sharedForGroups', contactData.sharedForGroups || []);
            setGroups(contactData?.sharedForGroups);
            setUsers(contactData?.sharedForUsers);
        }
    }, [contactData, isNewContact]);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchUsers());
            await dispatch(fetchGroups({}));
        }
        fetchData();
    }, [dispatch]);

    const handleMenageAccessChange = (res: boolean) => {

        if (!res) {
            setUsers(getValues('sharedForUsers'));
            setGroups(getValues('sharedForGroups'));
        }
       setValue(
            'sharedForUsers',
           users,
       )
        setValue(
            'sharedForGroups',

            groups,
        );
    };

    const handleAddPhoneNumber = () => {
        append({ msisdn: '', main: false });
    };


    const addContactTrigger = <Button className='p-6 m-6' buttonType='secondary'
                                      label='Add a team member' rightIcon='add' />;
    const menageAccessTrigger = <Button buttonType='secondary' label='Share access' size='small' leftIcon='settings' />;
    const modalContent = <AddContactModal selectedUsers={getValues('sharedForUsers')}
                                          selectedGroups={getValues('sharedForGroups')} setUsers={setUsers}
                                          setGroups={setGroups} />;

    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);

            const cleanedData = removeEmptyFields(data);

            const formattedData = {
                ...cleanedData,
                phoneNumbers: cleanedData.phoneNumbers.map((phone: any) => ({
                    msisdn: Number(phone.msisdn),
                    main: phone.main,
                })),
            };

            if (isNewContact) {
                await dispatch(addContact(formattedData));
            } else {
                await dispatch(updateContact({ ...formattedData, contactId: Number(id) }));
            }


        } catch (error) {
            console.error('Error submitting contact form:', error);
        } finally {
            setIsLoading(false);
            navigate('/contacts');
        }
    };


    const removeNumber = (res: boolean, index: number) => {
        if(!res) return;
        const newPhoneNumbers = [...getValues('phoneNumbers')];
        newPhoneNumbers.splice(index, 1);
        setValue('phoneNumbers', newPhoneNumbers);

    }
    const confirmRemoveRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const removeButton = (index:number) => <div onClick={() => {

        if (watch().phoneNumbers[index].msisdn.length)  {
        confirmRemoveRef.current?.openModal();}
        else {
            removeNumber(true, index);
        }
    }}>
        <Icon className='cursor-pointer' iconName='delete' iconType='destructive'
              background={false}/>
    </div>

    return (
        <div className={`w-full ` } >
            <Header header={id ? 'Edit contact' : 'New contact'} icon={'1F4D2'} />
            <div className='h-[calc(100vh-170px)] mr-4 overflow-y-auto'>
                <div className='flex items-center gap-4 mx-8'>
                    <Link to={'/contacts'}>
                        <Button className='pl-1' size={'small'} buttonType='secondary' rightIcon='arrow_back_ios' />
                    </Link>
                    <div>
                        Back to the contact list
                    </div>
                </div>
                <div className={` ${isMobile ? ' px-4 ' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin} `}>
                    <div className={`flex flex-col border  gap-6 ${isMobile ? ' mt-6' : ' m-8  '}` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                        <div className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                            <div className='font-semibold px-6 pt-6 text-lg'>
                                General information
                            </div>
                            <div className='text-grey-500 px-6 pb-6 text-sm'>
                                Update name and general information
                            </div>
                        </div>
                        <div className='px-6 pb-6 gap-6 grid '>
                            <div className={'block'}>
                                {fields.map((field, index) => (
                                    <div className='flex items-center mb-6' key={field.id}>

                                        <Controller
                                            name={`phoneNumbers.${index}.msisdn`}
                                            control={control}
                                            rules={{
                                                required: 'Phone number is required',
                                                validate: {
                                                    isValid: (value:any )=> {
                                                        console.log(value, isValidPhoneNumber(`+${value}`));
                                                        return isValidPhoneNumber(`+${value}`) || 'Phone number is not valid'
                                                    }
                                                },
                                            }}
                                            render={({ field}) => (

                                                <PhoneNumberInput
                                                    phoneNumber={field.value}
                                                    onPhoneNumberChange={(value:any)=>field.onChange(value)}
                                                    error={errors.phoneNumbers?.[index]?.msisdn ? errors.phoneNumbers[index].msisdn.message : undefined}
                                                    onBlur={field.onBlur}
                                                    />


                                            )}
                                        />

                                        {watch().phoneNumbers?.length > 1 && removeButton(index) }
                                        <ChModal
                                            callback={(res: boolean) => {
                                                removeNumber(res, index);
                                            }}
                                            title={'Delete phone number'}

                                            content={<div className='py-6'>
                                                Are you sure you want to delete this phone number?
                                            </div>}
                                            primaryLabel='Delete'
                                            secondaryLabel='Cancel'
                                            modalType='destructive'
                                            ref={confirmRemoveRef}

                                        />
                                    </div>
                                ))}

                                <div className='flex justify-end'>
                                    <Button onClick={handleAddPhoneNumber} size='small' buttonType='textPrimary'
                                            label='Add phone number'
                                            rightIcon='add' />
                                </div>
                            </div>

                            <div>
                                <form className='grid gap-6' onSubmit={handleSubmit(onSubmit)}>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        rules={{ required: 'First name is required' }}
                                        render={({ field }) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}
                                                callback={(value:any) => field.onChange(value)}
                                                className='max-w-full'
                                                label='First name'

                                                error={errors.firstName ? errors.firstName.message : undefined}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        rules={{ required: 'Last name is required' }}
                                        render={({ field }) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}

                                                callback={(value:any) => field.onChange(value)}
                                                className='max-w-full'
                                                label='Last name'
                                                error={errors.lastName ? errors.lastName.message : undefined}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            validate: {
                                                isValid: (value:any )=> value ? ( validateEmail(value) || 'Invalid email format') : true,
                                            },
                                        }}
                                        render={({ field }) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}
                                                callback={(value:any) => field.onChange(value)}
                                                className='max-w-full'

                                                label='Email address'
                                                error={errors.email ? errors.email.message : undefined}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="company"
                                        control={control}
                                        render={({ field }) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}
                                                callback={(value:any) => field.onChange(value)}
                                                className='max-w-full'

                                                label='Company'
                                                error={errors.company ? errors.company.message : undefined}
                                            />
                                        )}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={`flex flex-col border  ${isMobile ? ' mt-6' : ' mx-8 mt-8 '}` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                        <div className='font-semibold text-lg mb-0 pt-6 pr-6 pl-6'>
                            Contact availability
                        </div>
                        <div className='text-grey-500 text-sm mb-4 pr-6 pl-6'>
                            Public or private
                        </div>
                        <div className={`p-6 border-t ` + clsxMerge(borderStyles({theme}))}>
                            <ChRadio alignLeft={false} value={watch('availability')} className={'flex-col'}
                                     radioGroup={RadioGroup}
                                     callback={(value: string) => {
                                         console.log(value);
                                         setValue('availability', value)
                                     }} />
                        </div>
                    </div>
                    {watch('availability') === 'SHARED' && <div className={`flex flex-col border   m-8 ` + clsxMerge(borderStyles({theme}))}  >
                        <div>
                            <div className='p-6 font-semibold text-lg flex justify-between'>
                                <div>
                                    Share access
                                </div>
                                {Boolean(watch().sharedForUsers?.length || watch().sharedForGroups?.length) && <div>
                                    <ChModal callback={handleMenageAccessChange} title={'Share access'}
                                             trigger={menageAccessTrigger} content={modalContent} secondaryLabel='Cancel'
                                             primaryLabel='Confirm' />
                                </div>
                                }
                            </div>
                            <div className='px-6 pb-6 text-grey-500 text-sm'>
                                Give other members in your workspace access to this phone number
                            </div>
                            {Boolean(!watch().sharedForUsers?.length && !watch().sharedForGroups?.length) &&
                                <div className={`p-6 border-b border-t  flex justify-center ` + clsxMerge(borderStyles({theme}))}>
                                    <ChModal title={'Share access'} callback={handleMenageAccessChange}
                                             trigger={addContactTrigger} content={modalContent} secondaryLabel='Cancel'
                                             primaryLabel='Confirm' />
                                </div>}

                            {Boolean(watch().sharedForUsers?.length) && <>
                                <div className={`p-6 border-b border-t ` + clsxMerge(borderStyles({theme}))}>
                                    Users
                                </div>
                                <div>
                                    {(getValues().sharedForUsers?.map((user:any, index:number) => {

                                        return (
                                            <div className={`border-b  w-full ` + clsxMerge(borderStyles({theme}))} key={index}>
                                                <div className='flex gap-3  items-center pl-4 py-4'>
                                                    <Avatar size="md" type='initials'
                                                            color={user.iconColor ?? Colors.auto}
                                                            name={user.firstName + ' ' + user.lastName} />{user.firstName + ' ' + user.lastName}
                                                </div>
                                            </div>
                                        )
                                    }))
                                    }
                                </div>
                            </>}
                        </div>
                        {Boolean(watch().sharedForGroups?.length) && <>
                            <div className={`p-6 border-b ` + clsxMerge(borderStyles({theme}))}>
                                Groups
                            </div>
                            <div>
                                {getValues().sharedForGroups.map((group:any, index:number) => {
                                        return (
                                            <div className={`border-b ` + clsxMerge(borderStyles({theme}))}  w-full key={index}>
                                                <div className='flex gap-3  items-center pl-4 py-4'>
                                                    <Avatar size="md" type='icon'
                                                            color={group.iconColor ?? Colors.auto}
                                                            icon='home' />{group.name}
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </>
                        }
                    </div>}
                    <div className={` flex justify-end relative`}>
                        {
                            !isNewContact && <DeleteContactModal />
                        }
                        <div className={`flex gap-3 justify-end ${isMobile ? ' m-0' : ' m-8'}`}>
                            <Link to={'/contacts'}>
                                <Button buttonType='secondary' size='large' label={'Cancel'}></Button>
                            </Link>
                            <Button disabled={isLoading} size='large'  onClick={handleSubmit(onSubmit)}
                                    label={isNewContact ? 'Add contact' : 'Save changes'}></Button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AddEditContact;
