import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../Users/usersSlice";
import {RootState} from "../../store";
import {Msisdn} from "../Msisdns/msisdnsSlice";
import {ColorKey} from "../../../utilis/types/Types";
import {filterParams} from "../calls/callsSlice";
import {getApiUrl} from "../../../config";
import {showToast} from "../Notifications/notificationsSlice";




interface GroupsState {
    group: FetchGroupsResponse | undefined;
    groups: FetchGroupsResponse[];
    totalCount: number;
    status: string;
    error: string | undefined;
}

export interface GroupDTO {
    id: number;
    name: string;
    description: string | undefined;
    type: string | undefined;
    iconColor: ColorKey;
    members: number[];
    msisdns: number[];
}

export interface Group {
    id?: number;
    name: string;
    description: string | undefined;
    type: string | undefined;
    iconColor: ColorKey;
    users: User[];
    msisdns: Msisdn[];
}

export interface FetchGroupsResponse {

    group: {
        groupId: number;
        name: string;
        description: string;
        type: string;
        iconColor: ColorKey;
    },
    users: User[]
    msisdns: Msisdn[]
}

export interface GroupsList {
    groups: FetchGroupsResponse[];
    totalCount: number;
}

// Initial state
const initialState: GroupsState = {
    group: undefined,
    groups: [] as FetchGroupsResponse[],
    totalCount: 0,
    status: 'idle',
    error: undefined,
};

interface ParamsProps {
    phrase?: string;
    lastGroupId?: number;
    lastName?: string;
    sortBy?: string;
    msisdns?: number[]
    order?: string;
    groupId?: number;
}

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem('tenant'),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});


export const fetchGroupById = createAsyncThunk<FetchGroupsResponse[], number>('groups/fetchGroupById', async (groupId) => {
    try {
        const userId = Number(localStorage.getItem('userId'));
        const response = await axios.get(`${await getApiUrl()}/group/${userId}`, {
            headers: getHeaders(),
            params: {groupId}
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});
export const fetchGroups = createAsyncThunk<GroupsList, ParamsProps>('groups/fetchGroups', async ({
                                                                                                                 phrase,
                                                                                                                 lastGroupId,
                                                                                                                 lastName,
                                                                                                                 sortBy,
                                                                                                                 order,
                                                                                                                 msisdns,
                                                                                                                 groupId
                                                                                                             }) => {
    const userId = Number(localStorage.getItem('userId'));
    try {
        const response = await axios.get(`${await getApiUrl()}/group/${userId}`, {
            headers: getHeaders(),
            params: filterParams({phrase, lastGroupId, lastName, sortBy, msisdns, order, groupId}),
            paramsSerializer: params => {
                return Object.entries(params).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        if (value.length === 0) return;
                        return `${key}=${value.join(',')}`;
                    }
                    return `${key}=${value}`;
                }).join('&');
            }
        });
        return {groups: response.data, totalCount: response.headers['x-total-count']};
    } catch (error) {
        throw error;
    }
});

export const loadMoreGroups = createAsyncThunk<FetchGroupsResponse[], ParamsProps>('groups/loadMoreGroups', async ({
                                                                                                                       phrase,
                                                                                                                       lastGroupId,
                                                                                                                       lastName,
                                                                                                                       sortBy
                                                                                                                   }) => {
    const userId = Number(localStorage.getItem('userId'));
    try {
        const response = await axios.get(`${await getApiUrl()}/group/${userId}`, {
            headers: getHeaders(),
            params: filterParams({phrase, lastGroupId, lastName, sortBy}),
            paramsSerializer: params => {
                return Object.entries(params).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        if (value.length === 0) return;
                        return `${key}=${value.join(',')}`;
                    }
                    return `${key}=${value}`;
                }).join('&');
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

// Async thunk to add a new group
export const addGroup = createAsyncThunk('groups/addGroup', async (newGroup:Group, thunkAPI) => {

    const usersArray = Array.isArray(newGroup.users) ? newGroup.users : [];
     const data = {
         ...newGroup,
         members:[...usersArray.map(user => user.userId)],
         msisdns: newGroup.msisdns.map(msisdn => msisdn.msisdnId)
     }


    try {
        const response = await axios.post(`${await getApiUrl()}/group`, data, {
            headers: getHeaders()
        });
        const res = {
            group: {
                groupId: response.data,
                name: newGroup.name,
                description: newGroup.description ?? '',
                type: newGroup.type ?? '',
                iconColor: newGroup.iconColor
            },
            users: newGroup.users,
            msisdns: newGroup.msisdns
        };
        thunkAPI.dispatch(showToast({ description: 'Group added successfully!', type: 'success' }));
        return res;
    } catch (error) {
        thunkAPI.dispatch(showToast({ description: 'Failed to add group!', type: 'error' }));
        throw error;
    }
});

// Async thunk to update a group
export const updateGroup = createAsyncThunk('groups/updateGroup', async (updatedGroup: Group, thunkAPI) => {

    const data = {
        id: updatedGroup.id,
        name: updatedGroup.name,
        description: updatedGroup.description,
        type: updatedGroup.type,
        iconColor: updatedGroup.iconColor,
        members: updatedGroup.users.map(user => user.userId),
        msisdns: updatedGroup.msisdns.map(msisdn => msisdn.msisdnId)
    } as GroupDTO;

    try {
        await axios.patch(`${await getApiUrl()}/group/${updatedGroup.id}`, data, {
            headers: getHeaders()
        });
        thunkAPI.dispatch(showToast({ description: 'Group updated successfully!', type: 'success' }));
    } catch (error) {
        thunkAPI.dispatch(showToast({ description: 'Failed to update group!', type: 'error' }));
        throw error;
    }
    return updatedGroup;
});

// Async thunk to delete a group
export const deleteGroup = createAsyncThunk('groups/deleteGroup', async (groupId: number, thunkAPI) => {
    try {
        await axios.delete(`${await getApiUrl()}/group/${groupId}`, {
            headers: getHeaders()
        });
        thunkAPI.dispatch(showToast({ description: 'Group deleted successfully!', type: 'success' }));
    } catch (error) {
        thunkAPI.dispatch(showToast({ description: 'Failed to delete group!', type: 'error' }));
        throw error;
    }
    return groupId;
});



// Create slice
const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchGroupById.fulfilled,
                (state, action) => {
                    state.status = 'succeeded';
                    state.group = action.payload[0];
                }
            )
            .addCase(fetchGroups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = action.payload.groups;
                state.totalCount = action.payload.totalCount;
            })
            .addCase(fetchGroups.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addGroup.fulfilled, (state) => {
                state.status = 'succeeded';
                fetchGroups({});
            })
            .addCase(updateGroup.fulfilled, (state) => {
                state.status = 'succeeded';
                fetchGroups({});
            })
            .addCase(deleteGroup.fulfilled, (state, action) => {
                const payloadId = action.payload;
                if (payloadId !== undefined) {
                    state.groups = state.groups.filter((group) => group.group.groupId !== payloadId);
                }
            })
            .addCase(loadMoreGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = [...state.groups, ...action.payload];
            });
    },
});


export default groupsSlice.reducer;

export const selectGroupById = (state: RootState, groupId: number) => {
    return state.groups.groups.find(group => group.group.groupId == groupId);
};

