import {FormattedMessage} from 'react-intl';
import {Call} from '../../store/features/calls/callsSlice';
import listEmpty from "../../assets/list-empty.svg";
import {useEffect, useRef} from "react";
import {throttle} from "lodash";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";

import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { borderStyles, boxStyles } from "./helpers.tsx";
import {selectTheme} from "../../store/features/ThemeSwitcher/themeSwitcherSlice";
import {useSelector} from "react-redux";
import CallRow from "./CallRow";



interface RecentCallsProps {
    groupedCalls: { [key: string]: Call[] };
    reachedEnd: Function;
}

const RecentCalls = ({groupedCalls, reachedEnd}: RecentCallsProps) => {
    const isMobile = useMediaQuery('(max-width: 960px)');

    const theme = useSelector(selectTheme);
    const listContainerRef = useRef<HTMLDivElement | null>(null);
    if (!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    const reachedEndThrottled = throttle(() => {
        reachedEnd();
    }, 2000);


    const handleScroll = (e: any) => {
        const bottom = (e.target.scrollHeight - (e.target.scrollTop + 5)) <= e.target.clientHeight;
        if (bottom) {

            reachedEndThrottled();
        }
    };


    useEffect(() => {
        const listContainer = listContainerRef.current;
        if (listContainer) {
            listContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (listContainer) {
                listContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (

        <div ref={listContainerRef} className='h-[calc(100vh-300px)] overflow-y-auto pr-4 mr-4'>
            <div className={`border w-full border-x border-t ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>

                <p className={`text-lg font-semibold px-4 py-5 border-b ` + clsxMerge(borderStyles({theme}))}>
                    <FormattedMessage id="calls.recent" defaultMessage="Recent Calls"
                                      description="Recent calls header"/>
                </p>
                {sortedGroups.map(date => (
                    <div key={date}>
                        {Boolean(isMobile) && <p className={`text-[12px] font-semibold pl-4 py-5 border ` + clsxMerge(borderStyles({theme}))}>{date}</p>}
                        {Boolean(!isMobile) && <h6 className='pl-4 py-5 font-semibold '>{date}</h6>}
                        {groupedCalls[date].map((call: Call) => (

                            <CallRow call={call} isMobile={isMobile}/>

                        ))}
                    </div>
                ))}

                {sortedGroups.length === 0 &&
                    <div className='flex text-center  justify-center '>
                        <div className='w-[450px]  m-14'>
                            <div className='flex  justify-center w-full'>
                                <img className='m-4' src={listEmpty}/>
                            </div>
                            <h1 className='font-bold'>
                                No calls yet!
                            </h1>
                            <div className='mb-6 mt-2'>
                                Start making calls and they'll show up here.
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>


    );
};


export default RecentCalls;
