import {AsYouType, parsePhoneNumber} from "libphonenumber-js/max";
import { ChDropdown, ChInput } from "ch3-ui-lib";
import {useEffect, useState} from "react";
import { useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {selectSelectedPrefix, setPhoneNumber, setSelectedPrefix} from "../../../store/features/calls/dialerSlice";
import { getCallingCountries } from "../services/countryDataService";
import { FlagImage } from "react-international-phone";
import {CountryCode} from "libphonenumber-js/max";

interface Country {
    name: string | undefined;
    alpha2: string;
    alpha3: string;
    countryCallingCodes: readonly string[];
    currencies: readonly string[];
    emoji: string;
    ioc: string;
    languages: readonly string[];
    status: string;
}

export interface DropdownItem {
    label: string | undefined;
    value: any;
    key: string;
    prefixElement: JSX.Element;
}

interface CallInputProps {
    phoneNumber: string;
    error: string | undefined;
}

export const CallInput = ({ phoneNumber, error }: CallInputProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const language = navigator.language.split('-').at(-1);
        const defaultCountry = getCallingCountries().find((country) => country.alpha2 == language?.toUpperCase());
        dispatch(setSelectedPrefix({
            isoCountry: defaultCountry?.alpha2 ?? 'US',
            prefix: defaultCountry?.countryCallingCodes[0] ?? '+1',
        }));
    }, []);

    const selectedPrefix = useSelector(selectSelectedPrefix);
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [typedNumber, setTypedNumber] = useState(phoneNumber);
    const [phrase, setPhrase] = useState('');
    const [items, setItems] = useState<DropdownItem[]>([]);

    useEffect(() => {
        const country =   getCallingCountries().find((country) => country.alpha2 == selectedPrefix.isoCountry)

        setSelectedCountry(
         country
        );
    },[selectedPrefix])

    useEffect(() => {
        setTypedNumber(
            new AsYouType(selectedPrefix.isoCountry as CountryCode).input(phoneNumber),
        );
    }, [phoneNumber]);

    useEffect(() => {
        const filteredCountries = getCallingCountries()
            .filter(
                (callingCountry) =>
                    callingCountry.name &&
                    (callingCountry.name.toLowerCase().includes(phrase.toLowerCase()) ||
                        callingCountry.countryCallingCodes[0].includes(phrase))
            )
            .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

        const items: DropdownItem[] = filteredCountries.map((country) => {
            return {
                label: country.name,
                value: country,
                key: country.alpha2,
                prefixElement: <FlagImage size={24} iso2={country.alpha2.toLowerCase()} className={'ml-3'} />,
            };
        });
        setItems(items);
    }, [phrase]);

    const onPressCountry = (prefix: Country) => {
        setSelectedCountry(prefix);
        dispatch(
            setSelectedPrefix({
                isoCountry: prefix.alpha2,
                prefix: prefix.countryCallingCodes[0],
            })
        );
    };

    const flagTrigger = (
        <div className='flex items-center '>
            <FlagImage size={24} iso2={selectedPrefix.isoCountry.toLowerCase()} className={'mr-3'} />
        </div>
    );

    const dropdownGroup = [
        {
            items: items,
            callback: onPressCountry,
        }
    ];


    const handlePhoneNumberChange = (val: string) => {
        setTypedNumber(
            new AsYouType(selectedPrefix.isoCountry as CountryCode).input(val),
        );
        if (val === '') {
            setTypedNumber('');
            dispatch(setPhoneNumber(''));
        }
        if (val.startsWith('+') || val.startsWith('00')) {
            try {
                const phoneNumberParsed = val.startsWith('00') ? parsePhoneNumber(val.replace(/^00/, '+')) : parsePhoneNumber(val);

                if (phoneNumberParsed) {
                    const {country, countryCallingCode} = phoneNumberParsed;

                    if (countryCallingCode) {
                        dispatch(setSelectedPrefix({
                            isoCountry: country ?? 'US',
                            prefix: `+${countryCallingCode}`,
                        }));
                    }
                }
                dispatch(setPhoneNumber(val.replace(/[^\d+]/g, ''))
                );

            } catch (e) {
                // ignore as it is not yet a proper number with country code
            }
        }
        if (!val.startsWith('+') && !val.startsWith('00')) {
            try {
                dispatch(setPhoneNumber(
                    parsePhoneNumber(val, selectedPrefix.isoCountry as CountryCode)?.nationalNumber,
                ));

            } catch (e) {
               return;
            }
        }
    };

    return (
        <>
            <div className='flex items-end p-2 mb-2 '>
                <ChDropdown selected={[selectedCountry]} onPhraseChange={setPhrase} type={'search'} width={255} height={400} trigger={flagTrigger} dropdownGroup={dropdownGroup} />

            </div>

            <ChInput inputClassName=' text-xl font-semibold' name={'dialer'} type={'tel'} error={error} value={typedNumber} callback={(val:string) => handlePhoneNumberChange(val) }/>

        </>
    );
}
