
import axios from "axios";
import {getApiUrl} from "../../../config";

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'tenant': localStorage.getItem('tenant')
});
export const fetchCallRecord = async (recordingId: number) => {

    try {
        const response = await axios.get(`${await getApiUrl()}/api/v1/recordings/${recordingId}`, {
            headers: getHeaders(),
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {

        throw error;
    }

};
//TODO: Change to RTK after api refactor is ready
