import {AsYouType, parsePhoneNumber} from "libphonenumber-js/max";
import {useEffect, useState} from "react";
import {FlagImage} from "react-international-phone";
import {CountryCode} from "libphonenumber-js/max";
import {ChDropdown, ChInput} from "ch3-ui-lib";
import {getCallingCountries} from "../services/countryDataService";

interface Country {
    name: string;
    alpha2: string;
    countryCallingCodes: readonly string[];
    emoji: string;
}

interface DropdownItem {
    label: string | undefined;
    value: any;
    key: string;
    prefixElement: JSX.Element;
}

interface CallInputProps {
    phoneNumber: string | undefined;
    onPhoneNumberChange: (phoneNumber: number) => void;
    onBlur?: () => void;
    error?: string | undefined;
}

export const PhoneNumberInput = ({phoneNumber, onPhoneNumberChange, error, onBlur}: CallInputProps) => {
    const [typedNumber, setTypedNumber] = useState(phoneNumber);
    const [phrase, setPhrase] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<{   isoCountry: string;   prefix: string; } | null>(
        {
            isoCountry: 'US',
            prefix: '+1',
        }
    );
    const [items, setItems] = useState<DropdownItem[]>([]);


    useEffect(() => {
        try{
            const parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`);
            const country = getCallingCountries().find(
                (country) => country.alpha2 === parsedPhoneNumber?.country);
            setTypedNumber(  new AsYouType(country?.alpha2 as CountryCode).input(parsedPhoneNumber.nationalNumber));
            setSelectedCountry({
                isoCountry: country?.alpha2 ?? 'US',
                prefix: country?.countryCallingCodes[0] ?? '+1',
            });
        } catch (error) {
            setTypedNumber(phoneNumber?.toString());
        }

    }, []);

    useEffect(() => {
        const filteredCountries = getCallingCountries()
            .filter(
                (callingCountry) =>
                    callingCountry.name &&
                    (callingCountry.name.toLowerCase().includes(phrase.toLowerCase()) ||
                        callingCountry.countryCallingCodes[0].includes(phrase))
            )
            .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

        const items: DropdownItem[] = filteredCountries.map((country) => {
            return {
                label: country.name,
                value: country,
                key: country.alpha2,
                prefixElement: <FlagImage size={24} iso2={country.alpha2.toLowerCase()} className={'ml-3'}/>,
            };
        });
        setItems(items);
    }, [phrase])

    const handleCountrySelect = (country: Country) => {


        setSelectedCountry({
            isoCountry: country.alpha2,
            prefix: country.countryCallingCodes[0],
        })

            const phoneNumberParsed = parsePhoneNumber(`${country.countryCallingCodes[0] + typedNumber?.replace(/\D/g, '')}`);

            setTypedNumber(new AsYouType(country.alpha2 as CountryCode).input(phoneNumberParsed?.nationalNumber));
            onPhoneNumberChange(Number(phoneNumberParsed?.number.replace(/\D/g, '')));


    };

    const handlePhoneNumberChange = (val: string) => {
        if (val.startsWith('+') || val.startsWith('00')) {
            try {
                const phoneNumberParsed =  val.startsWith('00') ? parsePhoneNumber(val.replace(/^00/, '+')) : parsePhoneNumber(val);;

                if (phoneNumberParsed) {
                    const {country, countryCallingCode} = phoneNumberParsed;
                    onPhoneNumberChange(Number(val.replace(/\D/g, '')));
                    setSelectedCountry({
                            isoCountry: country ?? 'US',
                            prefix: `+${countryCallingCode}`,
                        })
                }


            } catch (e) {

            }
        } else {
            onPhoneNumberChange(Number(selectedCountry?.prefix + val.replace(/\D/g, '')));
        }
        const formattedNumber = new AsYouType(selectedCountry?.isoCountry as CountryCode).input(val);
        setTypedNumber(formattedNumber);
    };

    return (
        <div className='w-full'>
            <label className='text-sm'>Phone number</label>
            <div className='flex items-end mb-2 w-full items-end' >

                <ChDropdown
                    onPhraseChange={setPhrase}
                    type={'search'}
                    width={255}
                    height={400}
                    trigger={
                        <div className='flex items-start border-black border-b pb-2 mr-2 justify-center'>
                            <FlagImage size={24} iso2={selectedCountry?.isoCountry.toLowerCase()} className={'mx-3'} />
                        </div>
                    }
                    dropdownGroup={[{items, callback: handleCountrySelect}]}
                />
                <div className='w-full'>
                    <ChInput
                        className='max-w-full  w-full'
                        type='tel'
                        value={typedNumber}
                        callback={handlePhoneNumberChange}
                        onBlur={onBlur}
                    />
                </div>

            </div>
            {(typeof error === 'string') && <div className='pt-2 text-red-500 text-sm'>{error}</div>}
        </div>


    );
};
