import {Avatar, Button, ChColumn, ChDropdown, ChInput, Icon, ChTooltip, Loader} from "ch3-ui-lib";
import {FormattedMessage, useIntl} from "react-intl";
import {useCallback, useEffect, useState} from "react";
import Header from "../../components/header/Header";
import {Link} from "react-router-dom";
import {ChDropdownGroup, Colors,} from "../../utilis/types/Types";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {ContactListItem, getContacts, loadMoreContacts} from "../../store/features/Contacts/contactsSlice";
import noGroupsIcon from "../../assets/empty.svg";
import listEmpty from "../../assets/list-empty.svg";
import {FlagImage} from "react-international-phone";
import {getFlag} from "../../utilis/msisdnUtilis";
import {debounce} from 'lodash';
import {Group} from "../../store/features/groups/groupsSlice";
import {User} from "../../store/features/Users/usersSlice";
import {fetchUsers, getGroupsByPhrase} from "../../store/features/Searches/searchSlice";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import { borderStyles, boxStyles} from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";


export default function ContactsList() {
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const contactsData = useSelector((state: any) => state.contacts.contacts);
    const totalContacts = useSelector((state: RootState) => state.contacts.totalCount);

    const [userGroupPhrase, setUserGroupPhrase] = useState<string>('');
    const [phrase, setPhrase] = useState('');

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const [usersAndGroupsFilter, setUsersAndGroupsFilter] = useState<any[]>([]);
    const [ sort, setSort] = useState<any>({});

    const userGroupsAndUsers = useSelector((state: RootState) => state.search.searchGroupUsers);

    const intl = useIntl();
    const name = intl.formatMessage({id: 'contacts.name'});
    const phoneNumber = intl.formatMessage({id: 'contacts.phone-number'});
    const sharedFor = intl.formatMessage({id: 'contacts.shared-for'});
    const handleDataChange = (order:string, property:string) => {
            setSort({order, property});
    };
    const isMobile = useMediaQuery('(max-width: 960px)');
    const theme = useSelector((state: any) => state.theme.theme);


    const handleFilterSelect = (value: any) => {

        setUsersAndGroupsFilter((prev) => {
            const exists = prev.some(item => item === value);
            if (exists) {
                return prev.filter((item) => item !== value);
            } else {
                return [...prev, value];
            }
        });
    }
    useEffect(() => {

        const fetchData = async () => {
            await dispatch(getGroupsByPhrase({phrase: userGroupPhrase}));
            await dispatch(fetchUsers({phrase:userGroupPhrase}));
        }

        if(!userGroupPhrase.length ) {
            fetchData();

        }
        if(userGroupPhrase.length < 3) return;

        debounce(() => {
            fetchData();
        });

    }, [userGroupPhrase, dispatch]);


    const users = userGroupsAndUsers.sharedForUsers ? userGroupsAndUsers.sharedForUsers?.map((user) => ({
        key: `user${user.userId}`,
        label: user.firstName + ' ' + user.lastName,
        value: user,
        prefixElement: <div className='mx-2'><Avatar name={user.firstName + ' ' + user.lastName} size="xs" type='initials' color={user.iconColor ?? 'orange'}/></div>,
    })) : [];


    const groups =  userGroupsAndUsers.sharedForGroups ? userGroupsAndUsers.sharedForGroups?.map((group) => ({
        key:  `group${group.groupId}`,
        label: group.name,
        value: group,
        prefixElement: <div className='mx-2'><Avatar name={group.name} size="xs" type='icon' color={group.iconColor ?? 'orange'}
                                                     icon={group.icon ?? 'home'}/></div>,
        type: 'checkbox'
    })) : [];

    // const dropdownItems = users.map((user: any) => {
    //     return {label: user.firstName + ' ' + user.lastName, value: user}
    // })

    const dropdownGroup: ChDropdownGroup[] = [{
        title: 'Users',
        items: users,
        callback: (value: string) => {
            handleFilterSelect(value);
        },
        type: 'checkbox'
    },
        {
            title: 'Groups',
            items: groups,
            type: 'checkbox',
            callback: (value: string) => {
                handleFilterSelect(value);
            }
        }];
    const filterButton = <Button size={"small"} className='mb-6' label='Shared for' leftIcon='filter_list'
                                 buttonType='secondary'/>

    const createNewContactButton = () => {
        return (
            <Link to='/contacts/new'>
                <Button size='small' buttonType='primary' label='Create New'/>
            </Link>
        )
    }
    useEffect(() => {

        const order = sort.order;
        const sortBy = sort.property;

        const groups = usersAndGroupsFilter.filter((item) => item.hasOwnProperty('groupId'))
        const users = usersAndGroupsFilter.filter((item) => item.hasOwnProperty('userId'))

        const sharedForGroups = groups.map((item) => item.groupId);
        const sharedForUsers = users.map((item) => item.userId);

        const fetchData = async () => {
            await dispatch(getContacts({phrase, sharedForUsers, sharedForGroups, order , sortBy}));
        }
        setLoading(false);
        fetchData();

    }, [phrase, usersAndGroupsFilter,sort]);

    const fetchUsersGroups = async () => {
        await dispatch(getGroupsByPhrase({phrase:userGroupPhrase}));
        await dispatch(fetchUsers({phrase:userGroupPhrase}));
    };
    const debouncedFetchUsersGroups = useCallback(debounce(fetchUsersGroups, 1000), [userGroupPhrase, dispatch]);

    useEffect(() => {
        if(userGroupPhrase.length < 3) return;
        debouncedFetchUsersGroups();
        return () => {
            debouncedFetchUsersGroups.cancel();
        }

    }, [userGroupPhrase, dispatch]);

    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 1000), // 1 second delay
        []
    );

    const fetchMoreContacts = () => {
        dispatch(loadMoreContacts({phrase,lastFirstName: contactsData.at(-1).firstName, lastLastName: contactsData.at(-1).lastName, lastContactId: contactsData.at(-1).contactId, lastMsisdn: contactsData.at(-1).phoneNumber }));
    }
    return (
        <>
        {loading &&
            <div className={`flex w-full  ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <Loader/>
            </div>

        }
            {
                !loading &&
        <div className={`w-full ` }>
            <Header header='Contacts' icon={'1F4D2'} button={createNewContactButton()}/>
            <div className={`${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
            <div className={`flex justify-between` + `${isMobile? ' flex-wrap' : ' mx-8 no-wrap'} `}>
                    <ChDropdown trigger={filterButton} dropdownGroup={dropdownGroup}
                                onPhraseChange={(value: string) => setUserGroupPhrase(value)}
                                type='search'
                                selected={usersAndGroupsFilter}></ChDropdown>
                    <ChInput  placeholder={'Search'} className={`${isMobile? ' mb-[35px]' : 'min-w-[375px]'}`} value={phrase} prefixIcon={'search'}
                             callback={(value: string) => handlePhraseChange(value)}/>
                </div>

                <div className={`  ${isMobile ? ' ' : 'ml-8 pr-4 mr-4' } h-[calc(100vh-210px)] overflow-y-auto ` }>
                    <p className={`text-lg font-semibold px-4 py-5 border-x border-t ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}><FormattedMessage

                        id='contacts.list'/>
                        <span className={`border  rounded-sm px-2 text-xs mx-2 py-1`}>
                             {totalContacts}
                         </span>
                    </p>
                    {Boolean(contactsData.length) &&
                        <table className={`w-full border ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))} >
                            <thead>
                            <tr className={`border-b ` + clsxMerge(borderStyles({theme}))}>
                                <th className={`pl-4 py-3 w-full ` + clsxMerge(borderStyles({theme}))}
                                ><ChColumn propertyName='FIRST_NAME' label={name} callback={handleDataChange}/></th>
                                <th className='min-w-[300px] '
                                ><ChColumn callback={handleDataChange} propertyName='MSISDN' label={phoneNumber} /></th>
                                <th className='min-w-[200px]'
                                ><ChColumn callback={handleDataChange} propertyName='sharedFor' label={sharedFor}/></th>
                            </tr>
                            </thead>
                            <tbody >
                            {contactsData.map((contact: ContactListItem, index: number) => {
                                    return (
                                        <tr className={`border ` + clsxMerge(borderStyles({theme}))} key={index}>
                                            <Link to={`/contacts/details/${contact.contactId}`}
                                                  className='no-underline block w-full'>

                                                <td className='flex gap-3  items-center pl-4 py-4'><Avatar size="md"
                                                                                                           type='initials'
                                                                                                           color='auto'
                                                                                                           name={contact.firstName + ' ' + contact.lastName}/>{contact.firstName + ' ' + contact.lastName}
                                                </td>
                                            </Link>

                                            <td>
                                                {contact?.phoneNumber && <div
                                                    className=" flex items-center gap-2 text-center text-xs font-bold leading-none">
                                                    <FlagImage
                                                        iso2={getFlag(contact.phoneNumber) ?? 'us'}
                                                        className='w-4 h-3'/>    <ClickToCall noPrefix={true} msisdn={contact.phoneNumber}  />
                                                </div>}
                                            </td>

                                            <td>
                                                {contact.availability === "SHARED" && <div className=" justify-start items-center flex">
                                                    {
                                                        [...(contact.sharedForUsers ?? []), ...(contact.sharedForGroups ?? [])]
                                                            .slice(0, 6)
                                                            .map((item, index) => {
                                                                const isUser = (item as User).firstName !== undefined; // Type guard to check if item is User
                                                                return (
                                                                    <ChTooltip
                                                                        alignArrow='start'
                                                                        trigger={<div
                                                                        key={index}

                                                                        style={{right: `${index * 20}px`}}
                                                                        className={`p-[2px] bg-white relative`}
                                                                    >
                                                                        <Avatar
                                                                            size="md"
                                                                            type={isUser ? 'initials' : ((item as Group).id ? 'initials' : 'icon')}
                                                                            name={isUser ? `${(item as User).firstName} ${(item as User).lastName}` : (item as Group).name}
                                                                            icon={isUser ? undefined : 'home'}
                                                                            color={item.iconColor ?? Colors.auto}
                                                                        />
                                                                    </div>

                                                                    } content={
                                                                            isUser ? `${(item as User).firstName} ${(item as User).lastName}` : (item as Group).name
                                                                  }
                                                                               theme={'dark'}

                                                                  />

                                                                );
                                                            })
                                                    }
                                                    {
                                                        [...(contact.sharedForUsers ?? []), ...(contact.sharedForGroups ?? [])].length > 6 &&
                                                        <div
                                                            key={index}
                                                            style={{right: `120px`}}
                                                            className={`p-[2px] bg-white relative right-${index}`}
                                                        >
                                                            <Avatar
                                                                color={Colors.auto}
                                                                size="md"
                                                                type='initials'
                                                                name={`+ ${[...(contact.sharedForUsers ?? []), ...(contact.sharedForGroups ?? [])].length - 6}`}

                                                            />
                                                        </div>
                                                    }

                                                </div>}
                                                {contact.availability === "PRIVATE" && <div className="gap-3 justify-start items-center flex font-medium">
                                                    <Icon
                                                        size="md"
                                                        iconName='lock'

                                                    />
                                                    <div>Private</div>
                                                    </div>}
                                                {contact.availability === "PUBLIC" && <div className="gap-3 justify-start items-center flex font-medium">
                                                    <Icon
                                                        size="md"

                                                        iconName='public'

                                                    />
                                                    <div>Public</div>
                                                </div>}
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </table>}

                    {contactsData.length === 0 && Boolean(totalContacts) &&
                        <div className={`flex text-center  justify-center  border ` + clsxMerge(borderStyles({theme}))}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={noGroupsIcon}/>
                                </div>

                                <h1 className='font-bold'>
                                    No contacs found
                                </h1>
                                <div className='mb-6 mt-2'>
                                    No contacts found matching your search. Please double-check your criteria or
                                    consider adding new groups to expand your network!
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/contacts/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new contact'/>
                                    </Link>

                                </div>
                            </div>

                        </div>

                    }
                    {!totalContacts &&
                        <div className={`flex text-center  justify-center border ` + clsxMerge(borderStyles({theme}))}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={listEmpty}/>
                                </div>

                                <h1 className='font-bold'>
                                    No contacts yet!
                                </h1>
                                <div className='mb-6 mt-2'>
                                    Click below to add one now.
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/contacts/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new contact'/>
                                    </Link>
                                </div>
                            </div>

                        </div>

                    }
                    {
                        (Boolean(contactsData.length) && contactsData.length != totalContacts)  &&
                        <div className='flex justify-center my-4'>
                            <Button onClick={fetchMoreContacts} leftIcon={'more_horiz'} size='small' buttonType='secondary'
                                    label='Load more'/>
                        </div>
                    }
                </div>

            </div>
        </div>
}
</>
    )
}
