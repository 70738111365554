import {Icon} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import SelectList from "./SelectList";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {fetchMsisdns} from "../../../store/features/Msisdns/msisdnsSlice";
import {setSelectedPhoneNumber} from "../../../store/features/calls/dialerSlice";
import {hexToEmoji} from "../../Emoji/emoji";
import { borderStyles } from "./helpers";
import { clsxMerge } from "../../../utilis/classNameUtils";


const DialerHeader = () => {
    const [isOpened, setIsOpened] = useState(false);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const phoneNumbers = useSelector((state: RootState) => state.msisdns.msisdns);
    const selectedPhoneNumber = useSelector((state: RootState) => state.dialer.selectedPhoneNumber);
    const [phoneNumber, setPhoneNumber] = useState(selectedPhoneNumber);
    const theme = useSelector((state: any) => state.theme.theme);
    console.log(theme);

    const list = phoneNumbers.map((item) => {
        return {
            label:
                <div className='flex'>
                    <div className='text-xl mr-2 '>{hexToEmoji(item.icon)}</div>
                    <div className="self-stretch items-center flex">{item && item.label}</div>
                </div>,
            value: item,
            id: item.msisdnId
        }
    })

    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchUserNumbers = async () => {
            await dispatch(fetchMsisdns());
            if(!selectedPhoneNumber && phoneNumbers.length) {
                dispatch(setSelectedPhoneNumber(phoneNumbers[0]?.msisdn?.toString()));
            }
        }

        fetchUserNumbers();
    }, [dispatch, selectedPhoneNumber])

    useEffect(() => {
        setPhoneNumber(selectedPhoneNumber);
    }, [selectedPhoneNumber])

    const toggleIsOpened = () => {
        setIsOpened(!isOpened);
    };
    const onNumberSelect = (index: number) => {
        const phoneNumber = phoneNumbers.find((item) => item.msisdnId === list[index].id);
        if (phoneNumber) {
            dispatch(setSelectedPhoneNumber(phoneNumber));
        }
    }
    return (
        <>
            {Boolean(!isDialerHidden) && <>
                <div className={`flex gap-0 border-b border-solid ` + clsxMerge(borderStyles({theme}))}>
                    <div className={`flex justify-center items-center p-4 border-r border-solid w-16 h-16 ` + clsxMerge(borderStyles({theme}))}>
                        <Link to={'/settings'}> <Icon className={'padding'} iconName={'settings'}/> </Link>
                    </div>
                    <button onClick={() => setIsOpened(!isOpened)}
                            className=" items-center flex flex-1 gap-4 justify-between p-4">
                        <div className="text-sm leading-5">Call as</div>
                        <div
                            className="flex items-center gap-2 justify-center text-xs leading-5 text-center">
                            { phoneNumber &&
                                <div className='text-xl mr-2 '>{hexToEmoji(phoneNumber.icon)}</div>
                            }
                            <div className="self-stretch items-center flex">{phoneNumber && phoneNumber.label}</div>
                            <img loading="lazy"
                                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/012a16701585fb0643c010d642a6ade43cb66736f071512a40161a226fa3cb01?apiKey=b80f1a000b1b49588dd2774515b82ddf&"
                                 alt="Dropdown arrow" className="shrink-0 self-stretch my-auto w-3 aspect-square"/>
                        </div>
                    </button>
                </div>
                <SelectList label={'Numbers'} selectedItem={selectedPhoneNumber?.msisdnId} items={list} toggleIsOpened={toggleIsOpened} isOpened={isOpened} onSelect={onNumberSelect}/>
            </>}
        </>

    )
}
export default DialerHeader;

