import Header from "../../components/header/Header";
import {Avatar, Button, ChColumn, ChDropdown, ChInput, ChModal, Icon, Loader} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {hexToEmoji} from "../../components/Emoji/emoji";
import AddUserNumberModal from "./AddUserNumberModal";
import {useSelector} from "react-redux";
import {
    addGroup,
    updateGroup, Group, fetchGroupById
} from "../../store/features/groups/groupsSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {fetchUsers, User} from "../../store/features/Users/usersSlice";
import {useParams} from "react-router-dom";
import {fetchMsisdns, Msisdn} from "../../store/features/Msisdns/msisdnsSlice";
import {validateName} from "../../utilis/commonValidators";
import {useNavigate} from 'react-router-dom';
import DeleteGroupModal from "./DeleteGroup";
import {ColorKey, Colors, selectorItem} from "../../utilis/types/Types";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {showToast} from "../../store/features/Notifications/notificationsSlice";
import { borderStyles, boxStyles } from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";

const groupMenu = [
    {
        icon: 'article',
        label: 'General information',
        subLabel: 'Name and avatar',
        value: 'GENERAL_INFORMATION'
    },
    {
        icon: 'group',
        label: 'Team member',
        subLabel: 'Manage mebers',
        value: 'USERS'
    },
    {
        icon: 'phone',
        label: 'Phone numbers',
        subLabel: 'Manage phone numbers',
        value: 'PHONE_NUMBERS'
    }];


const GroupDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {id} = useParams<{ id: string }>();
    const isNewGroup = !Boolean(id);

    const [state, setState] = useState('GENERAL_INFORMATION');
    const [errors, setErrors] = useState({
        name: '',
        users: '',
        msisdns: ''
    });

    const isMobile = useMediaQuery('(max-width: 960px)');


    const groupData = useSelector((state: RootState) => state.groups.group);
    const usersData = useSelector((state: RootState) => state.users.users);
    const msisdnsData = useSelector((state: RootState) => state.msisdns.msisdns);


    const theme = useSelector((state: any) => state.theme.theme);

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);


    const initialFormData = isNewGroup ? {
        name: '',
        description: '',
        type: 'MULTIPLE',
        iconColor: Colors.orange,
        users: [],
        msisdns: []
    } as Group : {
        id: groupData?.group?.groupId,
        name: groupData?.group?.name,
        description: groupData?.group?.description,
        type: groupData?.group?.type,
        iconColor: groupData?.group?.iconColor,
        users: groupData?.users,
        msisdns: groupData?.msisdns
    } as Group;


    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(!isNewGroup);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (!isNewGroup) {
                await dispatch(fetchGroupById(Number(id)));
            }


            await dispatch(fetchUsers());
            await dispatch(fetchMsisdns());
            setIsLoading(false);
        };
        fetchData();

    }, [dispatch, isNewGroup]);

    useEffect(() => {
        if (groupData && !isNewGroup) {
            const data: Group = {
                id: groupData?.group?.groupId,
                name: groupData?.group?.name ?? '',
                description: groupData?.group?.description,
                type: groupData?.group?.type,
                iconColor: groupData?.group?.iconColor ?? Colors.orange,
                users: groupData?.users ?? [],
                msisdns: groupData?.msisdns ?? []
            };
            setFormData(data);
        }
    }, [groupData, isNewGroup]);


    const validateGroupName = () => {
        setErrors(prevState =>
            ({
                ...prevState,
                name: validateName(formData.name) ? '' : 'Group name is required'
            })
        );

    }
    const handleValidation = () => {
        const newErrors = {
            name: validateName(formData.name) ? '' : 'Group name is required',
            users: formData.users?.length ? '' : 'At least one user is required',
            msisdns: formData.msisdns?.length ? '' : 'At least one phone number is required'
        };

        const hasErrors = Object.values(newErrors).some(error => error !== '');
        setErrors(newErrors);
        if(newErrors.users || newErrors.msisdns
        ) {
            dispatch(showToast({title:'Cannot create a new group.' , description:'Please add at least one user and one phone number.', type: 'error' }))
        }
        return !hasErrors;
    };

    const selectedUserIds = formData.users?.map(user => user.userId);
    const selectedMsisdnIds = formData.msisdns?.map(msisdn => msisdn.msisdnId);


    const usersList: selectorItem[] = usersData?.filter(user => !selectedUserIds?.includes(user.userId)).map((user: User) => {
        return {
            key: user.userId.toString(),
            label: user.firstName + ' ' + user.lastName,
            value: user.userId.toString(),
            prefixElement: <div className='ml-2 flex items-center'><Avatar size="md" type='initials'
                                                                           color={user.iconColor ?? Colors.auto}
                                                                           name={user.firstName + ' ' + user.lastName}/>
            </div>

        }
    });
    const selectedUsers: selectorItem[] =  formData.users?.map((user: User) => {
        return {
            key: user.userId.toString(),
            label: user.firstName + ' ' + user.lastName,
            value: user.userId.toString(),
            prefixElement: <div className='ml-2'><Avatar size="md" type='initials'
                                                         color={user.iconColor ?? Colors.auto}
                                                         name={user.firstName + ' ' + user.lastName}/>
            </div>
        }
    });

    const msisdnsList: selectorItem[] = msisdnsData.filter(msisdn => !selectedMsisdnIds?.includes(msisdn.msisdnId)).map((msisdn: Msisdn) => {
        return {
            key: msisdn.msisdnId.toString(),
            label: msisdn.label,
            value: msisdn.msisdnId.toString(),
            prefixElement: <div className='text-3xl ml-2'>{hexToEmoji(msisdn.icon, 'w-6 h-6')}</div>
        }
    });

    const selectedMsisdns: selectorItem[] = msisdnsData.filter(msisdn => selectedMsisdnIds?.includes(msisdn.msisdnId)).map((msisdn: Msisdn) => {
        return {
            key: msisdn.msisdnId.toString(),
            label: msisdn.msisdn.toString(),
            value: msisdn.msisdnId.toString(),
            prefixElement: <div className='text-3xl'>{hexToEmoji(msisdn.icon)}</div>
        }
    });


    const [users, setUsers] = useState(selectedUsers ?? []);
    const [phoneNumbers, setPhoneNumber] = useState(selectedMsisdns ?? []);

    const handleMenageUsersChange = (res: boolean) => {
        if (!res) {
            setUsers([...selectedUsers]);
            return;
        }

        const matchedUsers = usersData.filter(userData => {
            return users.some(user => Number(user.value) === userData.userId);
        });


        setFormData(prevFormData => {
            return {
                ...prevFormData,
                users: matchedUsers,
            };
        });


        if (matchedUsers.length > 0 && errors.users) {
            setErrors(prevState => ({
                ...prevState,
                users: '',
            }));
        }
    };

    const handleMenagePhoneNumberChange = (res: boolean) => {
        if (!res) {
            setPhoneNumber([...selectedMsisdns]);
            return;
        }
        const matchedNumbers = msisdnsData.filter(msisdnData => {
            return phoneNumbers.some(msisdn => Number(msisdn.value) === msisdnData.msisdnId);
        });
        const data: Group = {
            ...formData,
            msisdns: matchedNumbers,
        };
        setFormData(data);

        if (matchedNumbers.length > 0 && errors.msisdns) {
            setErrors(prevState => ({
                ...prevState,
                msisdns: '',
            }));
        }
    };

    const setColor = (color: ColorKey) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            iconColor: Colors[color]
        }));
    };
    const groupColorOptions =
        [{
            callback: (value: ColorKey) => {
                console.log(formData, 'value')
                setColor(value)
            },
            items: [
                {
                    key: '1',
                    value: 'red',
                    label: 'Red',
                    prefixElement: <Avatar className='ml-2' size="xs" type='icon' color='red' icon='home'/>,
                },
                {
                    key: '2',
                    value: 'orange',
                    label: 'Orange',
                    prefixElement: <Avatar className='ml-2' size="xs" type='icon' color='orange' icon='home'/>,
                },
                {
                    key: '3',
                    value: 'yellow',
                    label: 'Yellow',
                    prefixElement: <Avatar className='ml-2' size="xs" type='icon' color='yellow' icon='home'/>,
                },
                {
                    key: '4',
                    value: 'green',
                    label: 'Green',
                    prefixElement: <Avatar className='ml-2' size="xs" type='icon' color='green' icon='home'/>,
                }]
        }];

    const groupColorPickerTrigger =

    <div className= {`border-b p-3 mb-2 ` + clsxMerge(borderStyles({theme}))}>

            <Avatar icon={'home'} size="md" type='icon' color={formData?.iconColor ?? Colors.orange}
                    name='John Doe'/>
        </div>


    const handleDataChange = (newData: any) => {
        setUsers(newData);
    };

    const manageUsersTrigger = <Button
        className={!formData.users?.length ? 'w-[215px] mr-6  h-[50px]' : 'mr-6 w-[160px] h-[50px]'}
        size={formData.users?.length ? 'small' : 'default'}
        leftIcon='settings'
        buttonType={'secondary'} label={formData.users?.length ? 'Manage users' : 'Share access'}></Button>

    const managePhoneNumbersTrigger = <Button className={'w-[250px] h-[50px] mr-6'}
                                              size={'small'}
                                              leftIcon='settings'
                                              buttonType={'secondary'}
                                              label={formData.users?.length ? 'Manage phone numbers' : 'Add phone number'}></Button>

    const addUserModal = <AddUserNumberModal type={'USERS'} users={usersList} data={selectedUsers} setData={setUsers}/>;
    const addPhoneNumberModal = <AddUserNumberModal type={'PHONE_NUMBER'} data={selectedMsisdns} msisdns={msisdnsList}
                                                    setData={setPhoneNumber}/>;
    const handleSubmit = async () => {
        setIsLoading(true)
        if (!handleValidation()) {
            setIsLoading(false)
            return;
        }
        if (isNewGroup) {
            await dispatch(addGroup(formData));
            setIsLoading(false)
        } else {
            await dispatch(updateGroup(formData));
            setIsLoading(false)
        }
        navigate('/groups');
    };

    if (isLoading) {
        return (
            <div
                className={`flex w-full  ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <Loader/>
            </div>


        );
    }
    return (
        <div className='w-full'>
            <Header header={isNewGroup ? 'New group' : 'Group'} icon={'1F46A'}/>
            <div className="overflow-y-scroll">
                <div className='ml-8 mb-6'>
                    <Link className='flex gap-4 items-center' to={'/groups'}>
                        <Button className='pl-1 mb-2    ' size={'small'} buttonType='secondary'
                                rightIcon='arrow_back_ios'/>
                        <div>
                            Back to the group list
                        </div>
                    </Link>
                </div>
                <div className={`flex ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>

                    <div
                        className={`${isMobile ? ' hidden' : ' inline-flex w-[300px] min-w-[300px] py-8 flex-col justify-start items-center gap-6 mx-8 h-[calc(100vh-325px)]'}`}>

                        {!Boolean(isNewGroup) &&
                            <div className="flex-col justify-start items-center gap-3 flex">
                                <Avatar size={"xxl"} type='icon' icon={'home'} color={formData?.iconColor ?? 'orange'}/>
                                <div className="flex-col justify-start items-center gap-2 flex">
                                    <div
                                        className=" text-center  text-xl font-semibold  leading-7">{formData?.name}</div>
                                </div>
                            </div>
                        }
                        {
                            Boolean(isNewGroup) &&
                            <div className="flex-col justify-start items-center gap-3 flex">
                                <Avatar size={"xxl"} type='icon' icon={'group'} color={'default'}/>
                            </div>
                        }

                        <div className="  self-stretch py-4 ">
                            {groupMenu.map((item) => (
                                <div key={item.value} onClick={() => setState(item.value)}
                                     className={`cursor-pointer flex gap-6 px-8 py-4 ${state === item.value && theme === 'light' ? 'bg-information-50' : ''} ${state === item.value && theme === 'dark' ? 'bg-[#393C41]' : ' '}`}>
                                    <Icon iconName={item.icon}/>
                                    <div className="flex flex-col ">
                                        <div className="text-sm font-semibold leading-5">{item.label}</div>
                                        <div className="mt-1 text-xs leading-5">{item.subLabel}</div>
                                    </div>

                                </div>
                            ))}
                        </div>

                    </div>

                    <div
                        className={`flex-col justify-start items-center gap-6 inline-flex w-full h-[calc(100vh-325px)] overflow-y-scroll overflow-x-hidden ${isMobile ? ' ' : ' px-8'}`}>
                        {
                            Boolean(state === 'GENERAL_INFORMATION') &&
                            <div className={`flex-col justify-start items-center gap-6 inline-flex mx-8 w-full ` + clsxMerge(boxStyles({theme}))}>
                                <div className='w-full'>
                                <div className={`flex justify-between border-x border-t  items-center ` + clsxMerge(borderStyles({theme}))}>
                                        <p className='text-lg font-semibold px-8 py-6 '><FormattedMessage
                                            id='groups.general-information'/>
                                        </p>
                                    </div>
                                
                                <div className={`w-full border  ` + clsxMerge(borderStyles({theme}))} >
                                        <div className='flex gap-6 px-8 py-4 items-end'>
                                            <div className={errors.name && ' pb-7'}>
                                                <ChDropdown trigger={groupColorPickerTrigger}
                                                            dropdownGroup={groupColorOptions}></ChDropdown>
                                            </div>
                                            <ChInput className='py-3' value={formData?.name} label='Name'
                                                     error={errors.name}
                                                     callback={(val: string) => {
                                                         validateGroupName();
                                                         setFormData(
                                                             {
                                                                 ...formData,
                                                                 name: val,

                                                             })
                                                     }}>
                                            </ChInput>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        {
                            Boolean(state === 'GENERAL_INFORMATION' || state === 'USERS') &&
                            <div className={`w-full ` + clsxMerge(boxStyles({theme}))} >
                            <div className={`flex justify-between border-x border-t  items-center ` + clsxMerge(borderStyles({theme}))}>

                                    <p className='text-lg font-semibold px-8 py-6 '>

                                        <div className='flex items-center'>
                                            <FormattedMessage
                                                id='groups.user-list'/>
                                            {Boolean(errors.users) &&
                                                ( <Icon iconName='report_problem' className={'mx-2'} iconType='destructive'/>)}
                                            {Boolean(!errors.users) &&
                                    <span className={`border  rounded-sm px-2 text-xs mx-2 py-1 ` + clsxMerge(borderStyles({theme}))}>
                                                {formData.users?.length}
                                            </span>}
                                        </div>

                                        <div className='pt-2 text-red-500 text-sm font-normal'>{errors.users}</div>
                                    </p>
                                    {Boolean(formData.users?.length) && <div>
                                        <ChModal callback={handleMenageUsersChange} title='Add group member'
                                                 trigger={manageUsersTrigger} content={addUserModal}
                                                 secondaryLabel='Cancel'
                                                 primaryLabel='Confirm'/>
                                    </div>
                                    }
                                </div>
                                <table className={`w-full border ` + clsxMerge(borderStyles({theme}))}>

                                    <thead>
                                    <tr className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                                    <th className='pl-4 py-3 w-full'
                                            onClick={() => handleDataChange(formData?.users)}
                                        ><ChColumn propertyName='name' label={'Full name'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Boolean(!formData.users?.length) && <div className='p-6 flex justify-center'>
                                        <ChModal title='Add team member' callback={handleMenageUsersChange}
                                                 trigger={manageUsersTrigger} content={addUserModal}
                                                 secondaryLabel='Cancel'
                                                 primaryLabel='Confirm'/>
                                    </div>}
                                    {
                                        formData.users?.map((user: User) => (
                                            <tr key={user.userId} className={`border-b ` + clsxMerge(borderStyles({theme}))} >

                                                <td className='flex gap-3  items-center pl-4 py-4'>
                                                    <Avatar size="md" type='initials'
                                                            color={user.iconColor ?? Colors.auto}
                                                            name={user.firstName + ' ' + user.lastName}/>
                                                    {user.firstName + ' ' + user.lastName}
                                                </td>
                                            </tr>))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                        {
                            Boolean(state === 'GENERAL_INFORMATION' || state === 'PHONE_NUMBERS' || state === 'USERS') &&
                            <div className=  {`w-full ` + clsxMerge(boxStyles({theme}))} >
                                                            <div className={`flex justify-between border-x border-t  items-center ` + clsxMerge(borderStyles({theme}))}>

                                    <div className='text-lg font-semibold px-8 py-6 '>

                                        <div className={'flex items-center'}>
                                            <FormattedMessage
                                                id='groups.phone-numbers'/>
                                            {Boolean(errors.msisdns) &&
                                                ( <Icon iconName='report_problem' className={'mx-2'} iconType='destructive'/>)}
                                            {Boolean(!errors.msisdns) &&
                                                (                                    <span className={`border  rounded-sm px-2 text-xs mx-2 py-1 ` + clsxMerge(borderStyles({theme}))}>

                                                {formData.msisdns?.length}
                                            </span>)}
                                        </div>





                                        <div className='pt-2 text-red-500 text-sm font-normal'>{errors.msisdns}</div>
                                    </div>

                                    {  Boolean(formData.msisdns?.length) && <div>
                                            <ChModal callback={handleMenagePhoneNumberChange}
                                                     title={'Add phone number'}
                                                     trigger={managePhoneNumbersTrigger}
                                                     content={addPhoneNumberModal}
                                                     secondaryLabel='Cancel'
                                                     primaryLabel='Confirm'/>
                                        </div>
                                    }
                                </div>
                                <table className={`w-full border  ` + clsxMerge(borderStyles({theme}))}>

                                    <thead>
                                    <tr className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                                    <th className='pl-4 py-3 w-full' onClick={() => handleDataChange([...users])}
                                        ><ChColumn propertyName='msisdn' label={'Phone numbers'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Boolean(!formData.msisdns?.length) &&
                                        <div className='p-6 flex justify-center'>
                                            <ChModal title={'Add phone number'}
                                                     callback={handleMenagePhoneNumberChange}
                                                     trigger={managePhoneNumbersTrigger}
                                                     content={addPhoneNumberModal}
                                                     secondaryLabel='Cancel'
                                                     primaryLabel='Confirm'/>
                                        </div>}
                                    {Boolean(formData.msisdns?.length) &&
                                        formData.msisdns?.map((number) => (
                                            <tr key={number.msisdnId} className={`border-b ` + clsxMerge(borderStyles({theme}))}>
                                                <td className='flex gap-3  items-center pl-4 py-4'>
                                                    <div className='text-3xl'>{hexToEmoji(number.icon ?? '1F4F1')}</div>
                                                    {number.label}
                                                </td>

                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>


                </div>

                <div className={`${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                    <div className={`mx-8 mt-8 flex  ${isNewGroup ? 'justify-end' : ' justify-between'}`}>

                        {
                            !isNewGroup && <DeleteGroupModal/>

                        }
                        <div className='flex gap-4'>
                            <Link to={'/groups'}>
                                <Button size={'large'} label='Cancel' buttonType='secondary'/>
                            </Link>
                            <Button disabled={isLoading} onClick={handleSubmit} size={'large'} label='Done'
                                    buttonType='primary'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default GroupDetails;